import React, { useState } from "react";
import { Typography, Button } from "@novaescola/eva-react-v2";
import { isMobile } from "react-device-detect";

type AnosType = {
  items: {
    title: string;
    slug: string;
    image: string;
  }[];
};

const Anos: React.FC<AnosType> = ({ items }: AnosType) => {
  const [hiddenCard, setHiddenCard] = useState(isMobile);

  return (
    <>
      <Typography variant="title" size={3} component="h2" className="eva-text-center title">
        Ou encontre materiais por ano
      </Typography>
      <div className="row">
        {items.map(({ title, slug, image }, index) => (
          <div
            className={`col-md-3 col-6 eva-margin-bottom-2 ${
              hiddenCard && index > 3 ? "hidden-card-ano-mobile" : ""
            }`}
            key={`ano-card-${title}`}
          >
            <a href={`/catalogo/${slug}`}>
              <div className="eva-card eva-bg-branco">
                <div className="eva-card-img">
                  <div className="eva-card-bg d-flex jc-center">
                    <img
                      src={image}
                      alt={`Ilustração referente a ${title}`}
                      title="Ilustração"
                      className="custom-card-img"
                    />
                  </div>
                </div>
                <div className="eva-card-info d-flex ai-center jc-center eva-text-center">
                  <Typography variant="title" size={6}>
                    {title}
                  </Typography>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="row d-flex jc-center eva-margin-top-1 eva-margin-bottom-6 eva-mobile-only">
        <Button onClick={() => setHiddenCard(!hiddenCard)} type="button" variant="outline" size="medium">
          {hiddenCard ? "Ver todos os anos" : "Ver menos anos"}
        </Button>
      </div>
    </>
  );
};

export default Anos;
