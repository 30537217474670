import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

type CarrosselProps = {
  items: {
    title: string;
    description: string;
    url: string;
    image?: string;
  }[];
  btnSeeAll?: {
    label: string;
    url: string;
  };
};

const Carrossel: React.FC<CarrosselProps> = ({ items, btnSeeAll }) => {
  return (
    <>
      <Swiper
        breakpoints={{
          320: {
            slidesPerView: 1.2,
          },
          480: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={4}
        spaceBetween={15}
        navigation={true}
        modules={[Navigation]}
        className="eva-react-carrossel eva-margin-bottom-4"
      >
        {items.map(({ title, description, url, image }) => (
          <SwiperSlide key={`item-${title}`}>
            <a href={url}>
              <div className="eva-card eva-bg-branco">
                <div className="eva-card-img">
                  <div className="eva-card-bg">
                    {image && <img src={image} alt="" className="card-img" />}
                  </div>
                </div>
                <div className="eva-card-info">
                  <p className="eva-card-title eva-text-bold">{title}</p>
                  <p className="eva-card-description eva-body-3">
                    {description}
                  </p>
                </div>
              </div>
            </a>
          </SwiperSlide>
        ))}

        {btnSeeAll && (
          <a
            href={btnSeeAll.url}
            className="eva-btn-outline eva-btn-medium eva-margin-center btn-see-all"
          >
            {btnSeeAll.label}
          </a>
        )}
      </Swiper>
    </>
  );
};

export default Carrossel;
