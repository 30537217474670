import React, { useEffect, useState } from "react";
import { Button, Typography } from "@novaescola/eva-react-v2";
import { StaticImage } from "gatsby-plugin-image";

import BreadCrumb from "../components/general/Breadcrumb";
import Layout from "../components/Layout";
import Seo from "../components/general/Seo";
import Carrossel from "../components/general/Carrossel";
import Anos from "../components/home/Anos";
import Diferenciais from "../components/home/Diferenciais";
import Select from "../components/general/Select";
import { anos, regioesComNacional } from "../components/general/_/values";

const IndexPage: React.FC = () => {
  const [regiaoSelecionada, setRegiaoSelecionada] = useState("");
  const [anoSelecionado, setAnoSelecionado] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    let url = "";

    if (regiaoSelecionada && anoSelecionado) {
      url = `${regiaoSelecionada}/${anoSelecionado}`;
    } else {
      url = "/catalogo";

      if (regiaoSelecionada) url += `/${regiaoSelecionada}`;
      else if (anoSelecionado) url += `/${anoSelecionado}`;
    }

    setUrl(url);
  }, [regiaoSelecionada, anoSelecionado]);

  return (
    <Layout heroClass="no-hero" headerClass="padding-header">
      <Seo
        title="Material Educacional"
        description="Encontre os materiais do professor e do aluno"
      />
      <section className="container-fluid home-section">
        <div className="container d-flex fd-column banner">
          <div className="row eva-margin-top-2">
            <div className="col-md-6 col-12 order-mobile2">
              <BreadCrumb items={[{ text: "Material Educacional" }]} />

              <Typography
                variant="title"
                size={4}
                component="h1"
                className="mobile-text-center"
              >
                Material Educacional
              </Typography>

              <Typography
                variant="subtitle"
                size={1}
                component="h2"
                className="mobile-text-center"
              >
                Feito por quem ensina, pensado para quem aprende
              </Typography>

              <Typography
                variant="subtitle"
                size={2}
                component="h3"
                className=" eva-margin-top-2 eva-tablet-and-greater-only"
              >
                + de 230 Materiais Educacionais gratuitos e alinhados à BNCC
              </Typography>
            </div>

            <div className="col-md-6 col-12 order-mobile1">
              <StaticImage
                src="https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE_MaterialEducacional2A_Header_537x353+1.png"
                alt="imagem"
              />
            </div>
          </div>

          <div className="row banner-search">
            <div className="col-md-3 col-12 container-select">
              <Select
                onChange={setRegiaoSelecionada}
                items={regioesComNacional}
                defaultOption="Estado"
                label="Estado"
              />
            </div>

            <div className="col-md-3 col-12 container-select">
              <div className="eva-select-wrapper">
                <Select
                  onChange={setAnoSelecionado}
                  items={anos}
                  defaultOption="Ano"
                  label="Ano"
                />
              </div>
            </div>

            <div className="col-md-3 col-12 container-select">
              <Button
                component="a"
                href={url}
                variant="principal"
                size="medium"
                type="button"
              >
                Buscar materiais
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid differentials-section">
        <div className="container d-flex fd-column banner">
          <Diferenciais />
        </div>
      </section>

      <section className="container-fluid region-section">
        <div className="container banner">
          <Typography
            variant="title"
            size={3}
            component="h2"
            className="eva-text-center title mobile-text-center"
          >
            Materiais Educacionais por Estado
          </Typography>

          <Carrossel
            items={regioesComNacional}
            btnSeeAll={{ label: "Ver todos os estados", url: "/catalogo" }}
          />
        </div>
      </section>

      <section className="container-fluid year-section">
        <div className="container banner">
          <Anos items={anos} />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
