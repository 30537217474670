import React from "react";
import { Typography } from "@novaescola/eva-react-v2";
import { StaticImage } from "gatsby-plugin-image";

const Diferenciais: React.FC = () => {
  return (
    <div className="row">
      <div className="col-md-6 col-12 eva-tablet-and-greater-only">
        <StaticImage
          src="https://s3.amazonaws.com/teste.dev.novaescola.org.br/materiaiseducacionais/ANE_MaterialEducacional2A_Page1_522x439+1.png"
          alt="imagem"
        />
      </div>

      <div className="col-md-6 col-12">
        <Typography variant="title" size={3} component="h2" className="eva-color-me-600 title">
          Conheça os diferenciais
        </Typography>
     
        <Typography variant="subtitle" size={2} component="h3" className="subtitle">
        Materiais Educacionais Nova Escola
        </Typography>

        <ul className="eva-body-1">
          <li>
            <strong>Livros gratuitos</strong> para professores e alunos
            alinhados à <strong>BNCC</strong>;
          </li>
          <li>
            Construídos por <strong>professores para professores;</strong>
          </li>
          <li>
            Proposta pedagógica baseada em <strong>metodologias ativas;</strong>
          </li>
          <li>
            <strong>Material prático</strong> de apoio ao planejamento das
            aulas, pensado para as{" "}
            <strong>necessidades reais da escola pública;</strong>
          </li>
          <li>
            Coleções especialmente produzidas para Estados parceiros,{" "}
            <strong>alinhadas ao currículo e ao contexto locais;</strong>
          </li>
          <li>
            <strong>Cursos gratuitos sobre o material</strong> para professores.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Diferenciais;
