import React from "react";

type SelectProps = {
  onChange: Function;
  items: {
    title: string;
    slug: string;
  }[];
  defaultOption: string;
  label: string;
};

const Select: React.FC<SelectProps> = ({ onChange, items, defaultOption, label }) => {
  return (
    <div>
      <label htmlFor={`${label}-select`} className="eva-label d-none">
        {label}
      </label>
      <div className="eva-select-wrapper">
        <select
          id={`${label}-select`}
          name={`${label}-select`}
          className="eva-select"
          defaultValue=""
          onChange={(event) => onChange(event.target.value)}
        >
          <option value="">{defaultOption}</option>
          {items.map(({ title, slug }) => (
            <option value={slug} key={`option-${slug}`}>
              {title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
